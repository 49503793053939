<div class="assignee-picker-dialog">
  <div class="assignee-picker-dialog__header">
    <div class="assignee-picker-dialog__header-main">
      <div class="assignee-picker-dialog__header-main-title">
        {{ 'assigneePicker.dialog.selectAssignees' | translate }}
      </div>

      <app-button
        (click)="dialogRef.close()"
        [ccTooltip]="'common.buttons.close' | translate"
        [size]="ButtonSize.Big"
        [type]="ButtonType.Ghost"
      >
        <i
          class="icon-close"
          slot="ghost-icon"
        ></i>
      </app-button>
    </div>

    <cc-tab-selector
      [tabs]="tabs"
      [selectedTabId]="activeTabId"
      (tabSelect)="handleTabChange($any($event.id))"
      [inlinePadding]="12"
    />
  </div>

  <div
    class="assignee-picker-dialog__content"
    [ccAutoAnimate]
  >
    @switch (activeTabId) {
      @case ('contacts') {
        <cc-assignee-picker-dialog-contacts-table
          [companyId]="dialogData.companyId"
          [contactIds]="contactIds"
          (contactIdsChange)="handleContactsChange($event)"
          class="assignee-picker-dialog__content-tab"
        />
      }
      @case ('departments') {
        <cc-assignee-picker-dialog-departments-table
          [companyId]="dialogData.companyId"
          [departmentIds]="departmentIds"
          (departmentIdsChange)="handleDepartmentsChange($event)"
          class="assignee-picker-dialog__content-tab"
        />
      }
    }
  </div>

  <div class="assignee-picker-dialog__actions">
    <app-button
      (click)="dialogRef.close()"
      [type]="ButtonType.Secondary"
      [size]="ButtonSize.Small"
    >
      {{ 'common.buttons.cancel' | translate }}
    </app-button>

    <app-button
      (click)="save()"
      [disabled]="form.invalid"
      [type]="ButtonType.Primary"
      [size]="ButtonSize.Small"
    >
      @switch (activeTabId) {
        @case ('contacts') {
          {{ 'assigneePicker.dialog.saveContacts' | translate }}
        }
        @case ('departments') {
          {{ 'assigneePicker.dialog.saveDepartments' | translate }}
        }
      }
    </app-button>
  </div>
</div>
